<!--
 * @Descripttion: 首页组件
 * @Author: zhu
 * @Date: 2024-06-24 11:22:40
 * @LastEditors: zhu
 * @LastEditTime: 2024-07-16
--> 

<template>
  <div class="home" id="home" name="home">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel height="460px">
        <el-carousel-item v-for="item in carousels" :key="item.id">
          <a :href="item.source_url">
            <img style="height:460px;" v-lazy="item.attach.path" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播图END -->
    <div class="main-box">
      <div class="main">
        <!-- 商品展示区域 -->
        <div class="appliance" v-for="(item, index) in homeCategory" :key="index">
			<template v-if="item.is_home === 'yes'">
				<div class="box-hd">
					<div class="title">{{ item.home_title }}</div>
					<div class="more" v-if="item.children && item.children.length > 0">
						<MyMenu :val="allChildren(item.id, item.children)" @fromChild="getChildMsg($event, item.id)"></MyMenu>
					</div>
				</div>
				<div class="box-bd">
					<div class="list">
						<div v-if="listLoading[item.id]" style="font-size: 32px; text-align: center; color: #666; padding-top: 80px;">
							<i class="el-icon-loading"></i>
						</div>
						<MyList v-else :list="currentList && currentList[item.id]" :isMore="false"></MyList>
					</div>
				</div>
			</template>
        </div>
        <!-- 商品展示区域END -->
      </div>
    </div>
  </div>
</template>
<script>
import * as carouselsAPI from '@/api/carousels/'
import * as productsAPI from '@/api/products/'
import categoryAPI from '@/api/category.js'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
		carousels: [], // 轮播图数据
		ads: [], //首页广告图
		televisionsList: '', // 小米电视商品列表
		applianceList: {}, // 家电商品列表
		currentList: {},
		applianceHotList: '', //热门家电商品列表
		applianceActive: [], // 当前选中的商品分类
		accessoryActive: 1, // 配件当前选中的商品分类
		cateArr: [], // 商品分类
		start: 1,
		limit: 10,
		homeCategory: [],
		listLoading: [],
		homeCategoryIds: []
    }
  },
	computed: {
		...mapGetters(['getCategory'])
	},
  watch: {
    // 当前选中的商品分类，响应不同的商品数据
    applianceActive: function(val) {
		val.map((item, key) => {
			if (this.applianceList.hasOwnProperty(item))
				this.$set(this.currentList, key, this.applianceList[item])
			else
				this.getProduct(key, item)
		})
    },
	getCategory: function(val) {
		this.getHomeCategory(val)
	}
  },
  activated() {
    // 获取首页数据
    this.load()
  },
  methods: {
	allChildren(topId, children) {
		let res = []
		res.push({ id: topId, name: '全部' })
		children.map(it => {
			res.push(it)
		})
		return res
	},
    load() {
      carouselsAPI
        .listCarousels({ cid: 1 })
        .then(res => {
          if (res.status_code == 200) {
            this.carousels = res.data
          } else {
            // this.notifyError('获取轮播图失败', res.msg)
          }
        })
        .catch(err => {
          // this.notifyError('获取轮播图失败', err)
        })
		
		// carouselsAPI.listCarousels({ cid: 2 }).then(res => {
		// 	if (res.status_code == 200) {
		// 		this.ads = res.data
		// 	} else {
		// 		// this.notifyError('获取轮播图失败', res.msg)
		// 	}
		// }).catch(err => {
		// 	// this.notifyError('获取轮播图失败', err)
		// })
		this.getHomeCategory(this.getCategory)
		// this.getProduct(1)
		// this.getProduct(2)
    },
    // 获取家电模块子组件传过来的数据
    getChildMsg(val2, val) {
		this.$set(this.applianceActive, val, val2)
		// console.log(this.applianceActive)
    },
	getHomeCategory(val) {
		let index = 0
		this.homeCategoryIds = []
		val.map(item => {
			if (item.is_home === 'yes'){
				this.$set(this.homeCategory, index, { id: item.id, name: item.name, is_home: item.is_home, home_title: item.home_title, children: item.children ? item.children : [] })
				// this.getProduct(item.id)
				this.homeCategoryIds.push(item.id)
				this.$set(this.listLoading, item.id, true)
				index ++
			} else {
				if (item.children && item.children.length > 0) {
					item.children.map(cItem => {
						if (cItem.is_home === 'yes') {
							this.$set(this.homeCategory, index, { id: cItem.id, name: cItem.name, is_home: cItem.is_home, home_title: cItem.home_title, children: cItem.children ? cItem.children : [] })
							// this.getProduct(cItem.id)
							this.homeCategoryIds.push(cItem.id)
							this.$set(this.listLoading, cItem.id, true)
							index ++
						} else {
							if (cItem.children && cItem.children.length > 0) {
								cItem.children.map(ccItem => {
									if (ccItem.is_home === 'yes') {
										this.$set(this.homeCategory, index, { id: ccItem.id, name: ccItem.name, is_home: ccItem.is_home, home_title: ccItem.home_title, children: ccItem.children ? ccItem.children : [] })
										// this.getProduct(ccItem.id)
										this.homeCategoryIds.push(ccItem.id)
										this.$set(this.listLoading, ccItem.id, true)
										index ++
									}
								})
							}
						}
					})
				}
			}
		})
		if (this.homeCategoryIds.length > 0) {
			this.getMutiProduct()
		}
	},
    getProduct(categoryID, categoryID2 = 0) {
		this.$set(this.listLoading, categoryID, true)
		const cateId = categoryID2 ? categoryID2 : categoryID
		if (!this.applianceList.hasOwnProperty(cateId))
      productsAPI
        .listProducts( { category_id: cateId, status: 'normal', page: this.page, limit: this.limit } )
        .then(res => {
			this.$set(this.listLoading, categoryID, false)
          if (res.status_code === 200) {
			this.$set(this.applianceActive, categoryID, cateId)
			this.$set(this.applianceList, cateId, res.data.data)
			this.$set(this.currentList, cateId, res.data.data)
          } else {
            // this.notifyError('获取产品失败', res.msg)
          }
        })
        .catch(err => {
			this.$set(this.listLoading, categoryID, false)
          // this.notifyError('获取产品失败', err)
        })
    },
	getMutiProduct() {
		this.homeCategoryIds.map(item => {
			this.$set(this.listLoading, item, true)
		})
		productsAPI.listProducts( { category_ids: this.homeCategoryIds.join(','), status: 'normal', page: this.page, limit: this.limit * this.homeCategoryIds.length } ).then(res => {
			if (res.status_code === 200) {
				this.homeCategoryIds.map(item => {
					this.$set(this.listLoading, item, false)
					if (!this.applianceList.hasOwnProperty(item)){
						this.$set(this.applianceActive, item, item)
						let list1 = []
						res.data.data.map(item1 => {
							if (parseInt(item1.post_category_id) === parseInt(item)) {
								list1.push(item1)
							}
						})
						this.$set(this.applianceList, item, list1)
						let list2 = []
						res.data.data.map(item1 => {
							if (parseInt(item1.post_category_id) === parseInt(item)) {
								list2.push(item1)
							}
						})
						this.$set(this.currentList, item, list2)
					}
				})
			} else {
				// this.notifyError('获取产品失败', res.message)
			}
		}).catch(err => {
		})
	}
  },
  beforeRouteEnter(to, from, next) {
    // 添加背景色
    document
      .querySelector('body')
      .setAttribute('style', 'background-color:#ffffff')
    next()
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document
      .querySelector('body')
      .setAttribute('style', 'background-color:#f5f5f5')
    next()
  }
}
</script>
<style scoped>
@import '../assets/css/index.css';
.box-hd:after {
	display: block;
	content: '';
	clear: both;
}
.el-carousel__container .el-carousel__item a {
	display: block;
	position: absolute;
	left: 50%;
	width: 5000px;
	margin-left: -2500px;
	top: 0;
	text-align: center;
}
</style>